import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';

@Component({
             selector: 'app-repolist',
             templateUrl: './repolist.component.html',
             styleUrls: ['./repolist.component.scss']
           })
export class RepolistComponent implements OnInit {

  data: string[] = [];

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    this.http.get<string[]>('repos', {observe: 'body'}).subscribe(data => {
      this.data = data;
    });
  }

}

import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Group} from '../grouplist/grouplist.component';
import {User} from '../userlist/userlist.component';

@Component({
             selector: 'app-permissions',
             templateUrl: './permissions.component.html',
             styleUrls: ['./permissions.component.scss']
           })
export class PermissionsComponent implements OnInit {

  repo: string;
  data: Permission[] = [];
  users: any;

  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.url.subscribe(url => this.reload(url[url.length - 1].path));
    this.loadUsersAndGroups();
  }

  reload(repo: string) {
    this.repo = repo;
    this.http.get<Permission[]>(`repos/${repo}`, {observe: 'body'}).subscribe(data => {
      this.data = data;
    });
  }

  loadUsersAndGroups() {
    let uag = [];
    this.http.get<Group[]>('groups', {observe: 'body'}).subscribe(groups => {
      uag = groups.map(g => {
        return {
          name: g.name,
          groupPermission: true,
          label: '@' + g.name
        };
      }).sort((a, b) => a.name.localeCompare(b.name));

      this.http.get<User[]>('users', {observe: 'body'}).subscribe(users => {
        uag = [...uag, ...users.sort((a,b) => a.login.localeCompare(b.login)).map(user => {return {name: user.login, groupPermission: false, label: `${user.login} (${user.name})`}; })];
        this.users = uag;
      });

    });

  }

  removePermission(i: number) {
    this.data.splice(i, 1);
  }

  addPermission(name: string, level: string) {
    const permission = this.users.find(x => x.name === name);
    permission.accessLevel = level;
    this.data = [...this.data, permission];
  }

  commitChanges() {
    this.http.post(`repos/${this.repo}`, this.data, {observe: 'response'}).subscribe(() => this.reload(this.repo));
  }
}

export class Permission {
  public name: string;
  public groupPermission: boolean;
  public accessLevel: string;
}

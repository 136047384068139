<div class="container">
  <ul class="nav nav-pills">
    <li><a href="/">Home</a></li>
    <!--<li><a routerLinkActive="active" routerLink="/login">Login</a></li>
    <li><a (click)="logout()">Logout</a></li>-->
    <li>{{ app.authenticated ? 'Angemeldet' : 'Nicht angemeldet' }}</li>
  </ul>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-2"><app-userlist></app-userlist></div>
    <div class="col-md-2"><app-grouplist></app-grouplist></div>
    <div class="col-md-2"><app-repolist></app-repolist></div>
    <div class="col-md-4"><router-outlet></router-outlet></div>
    <div class="col-md-1"></div>
  </div>
</div>



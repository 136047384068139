import {HttpClient} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {User} from '../userlist/userlist.component';

@Component({
  selector: 'app-grouplist',
  templateUrl: './grouplist.component.html',
  styleUrls: ['./grouplist.component.scss']
})
export class GrouplistComponent implements OnInit {

  data: Group[] = [];
  addGroupForm;

  constructor(private http: HttpClient, private formBuilder: FormBuilder) {
    this.addGroupForm = this.formBuilder.group({
                                                groupname: ''
                                              });}

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    this.http.get<Group[]>('groups', {observe: 'body'}).subscribe(data => {
      this.data = data;
    });
  }

  onSubmit(groupData) {
    this.http.post('groups', {name: groupData.groupname, users: []}, {observe: 'response'}).subscribe(() => {
      this.reload();
      this.addGroupForm.reset();
    });
  }

}

export class Group {
  public name: string;
  public users: User[];
}

import {HttpClient} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Group} from '../grouplist/grouplist.component';
import {Permission} from '../permissions/permissions.component';
import {User} from '../userlist/userlist.component';

@Component({
  selector: 'app-groupdetails',
  templateUrl: './groupdetails.component.html',
  styleUrls: ['./groupdetails.component.scss']
})
export class GroupdetailsComponent implements OnInit {

  group: string = '';
  data: Group = { name: '', users: [] };
  users: User[] = [];
  newUser;

  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.url.subscribe(url => this.reload(url[url.length -1].path));
    this.http.get<User[]>('users', {observe: 'body'}).subscribe(users => this.users = users);
  }

  reload(group: string) {
    this.group = group;
    this.http.get<Group>(`groups/${group}`, {observe: 'body'}).subscribe(data => {
      this.data = data;
    });
  }

  removeUser(index: number) {
    this.data.users.splice(index, 1);
  }

  addUser(login: string) {
    const user = this.users.find(x => x.login === login);
    this.data.users = [...this.data.users, user];
  }

  commitChanges() {
    this.http.post('groups', this.data, {observe: 'response'}).subscribe(() => this.reload );
  }
}

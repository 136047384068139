import {HTTP_INTERCEPTORS, HttpClientModule, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppService} from './app.service';
import {GroupdetailsComponent} from './entities/groupdetails/groupdetails.component';
import {GrouplistComponent} from './entities/grouplist/grouplist.component';
import {HomeComponent} from './entities/home/home.component';
import {LoginComponent} from './entities/login/login.component';
import {PermissionsComponent} from './entities/permissions/permissions.component';
import {RepolistComponent} from './entities/repolist/repolist.component';
import {UserlistComponent} from './entities/userlist/userlist.component';

const routes: Routes = [
  {path: 'repositories/:repo', component: PermissionsComponent},
  {path: 'usergroups/:group', component: GroupdetailsComponent},
  {path: 'login', component: LoginComponent}
];

@Injectable()
export class XhrInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const xhr = req.clone({headers: req.headers.set('X-Requested-With', 'XmlHttpRequest')});
    return next.handle(xhr);
  }
}

@NgModule({
            declarations: [
              AppComponent,
              HomeComponent,
              UserlistComponent,
              RepolistComponent,
              GrouplistComponent,
              PermissionsComponent,
              GroupdetailsComponent,
              LoginComponent
            ],
            imports: [
              RouterModule.forRoot(routes),
              BrowserModule,
              AppRoutingModule,
              HttpClientModule,
              FormsModule,
              ReactiveFormsModule
            ],
            providers: [
              {provide: HTTP_INTERCEPTORS, useClass: XhrInterceptor, multi: true}
            ],
            bootstrap: [AppComponent]
          })
export class AppModule {}

<h1 class="h3">Groups</h1>
<form [formGroup]="addGroupForm" (ngSubmit)="onSubmit(addGroupForm.value)">
  <div class="container">
    <div class="row"><label class="label label-primary" for="groupname">Name:</label></div>
    <div class="row"><input type="text" id="groupname" formControlName="groupname"></div>
    <div class="row">
      <button type="submit" class="btn btn-primary">Speichern</button>
    </div>
  </div>
</form>
<ul class="list-unstyled">
  <li *ngFor="let item of data">
    <a routerLink="/usergroups/{{item.name}}">{{item.name}}</a>
  </li>
</ul>

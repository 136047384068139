import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';

@Component({
             selector: 'app-userlist',
             templateUrl: './userlist.component.html',
             styleUrls: ['./userlist.component.scss']
           })
export class UserlistComponent implements OnInit {

  data: User[] = [];
  addUserForm;

  constructor(private http: HttpClient, private formBuilder: FormBuilder) {
    this.addUserForm = this.formBuilder.group({
                                                login: '', name: '', password: ''
                                              });
  }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    this.http.get<User[]>('users', {observe: 'body'}).subscribe(data => {
      this.data = data;
    });
  }

  onSubmit(userData) {
    console.log("saving " + JSON.stringify(userData));
    this.http.post('users', userData, {observe: 'response'}).subscribe(() => {
      this.reload();
      this.addUserForm.reset();
    });
  }

}

export class User {
  public login: string;
  public name: string;
}

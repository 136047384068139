<h1 class="h3">Accounts</h1>
<hr>
<form [formGroup]="addUserForm" (ngSubmit)="onSubmit(addUserForm.value)">
  <div class="container">
    <div class="row"><label class="label label-primary" for="login">Login:</label></div>
    <div class="row"><input type="text" id="login" formControlName="login"></div>
    <div class="row"><label class="label label-primary" for="name">Name:</label></div>
    <div class="row"><input type="text" id="name" formControlName="name"></div>
    <div class="row"><label class="label label-primary" for="password">Passwort:</label></div>
    <div class="row"><input type="password" id="password" formControlName="password"></div>
    <div class="row">
      <button type="submit" class="btn btn-primary">Speichern</button>
    </div>
  </div>
</form>
<hr>
<ul class="list-unstyled">
  <li *ngFor="let user of data">{{ user.login }} ({{user.name}})</li>
</ul>

<div *ngIf="group" class="container">
  <h1 class="h3">Gruppe {{group}}</h1>

  <table>
    <tr *ngFor="let item of data.users; let i = index">
      <td>{{ item.login }}</td>
      <td style="padding-left: 2em">{{ item.name }}</td>
      <td style="padding-left: 2em">
        <button type="button" (click)="removeUser(i)">entf</button>
      </td>
    </tr>
  </table>

  <div class="row">
    <select #userselect>
      <option *ngFor="let user of users" [value]="user.login">{{ user.login }} ({{user.name}})</option>
    </select>

    <button type="button" (click)="addUser(userselect.value)">Hinzufügen</button>
  </div>

  <div class="row">
    <button class="btn btn-primary" type="button" (click)="commitChanges()">Speichern</button>
  </div>

</div>


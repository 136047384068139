<div class="container-fluid">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-2"><app-userlist></app-userlist></div>
    <div class="col-md-2"><app-grouplist></app-grouplist></div>
    <div class="col-md-2"><app-repolist></app-repolist></div>
    <div class="col-md-4"></div>
    <div class="col-md-1"></div>
  </div>
</div>

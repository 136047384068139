<div *ngIf="repo">
  <h1 class="h3">Repository {{repo}}</h1>
  <table class="table-responsive">
    <tr *ngFor="let item of data; let i = index">
      <td [ngStyle]="{'font-weight': item.groupPermission ? 'bold' : 'normal'}">{{ item.name }}</td>
      <td style="padding-left: 2em">{{ item.accessLevel }}</td>
      <td style="padding-left: 2em">
        <button type="button" (click)="removePermission(i)">entf</button>
      </td>
    </tr>
  </table>

  <div class="row">
    <select #userselect>
      <option *ngFor="let user of users" [value]="user.name">{{user.label}}</option>
    </select>

    <button type="button" (click)="addPermission(userselect.value, 'READ')">READ</button>
    <button type="button" (click)="addPermission(userselect.value, 'WRITE')">WRITE</button>
  </div>

  <div class="row">
    <button class="btn btn-primary" type="button" (click)="commitChanges()">Speichern</button>
  </div>

</div>
